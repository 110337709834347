import { useEffect, useState } from "react";
import { settingsMenu } from "@utils/constants";
import { useLocation, useNavigate } from "react-router-dom";

const useSettingMenu = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const initialState = settingsMenu[0];
    const [current, setCurrent] = useState(initialState);

    useEffect(() => {
        let opt = settingsMenu.find((option) => option.path === pathname);
        if (!opt) {
            opt = settingsMenu[0]
        }
        setCurrent(opt);
    }, [pathname]);

    const handleChange = (option) => {
        setCurrent(option);
        navigate(option.path);
    };

    const reset = () => setCurrent(initialState);

    return { current, handleChange, reset };
};

export default useSettingMenu;
