import axios from "axios";

const authStateKeyName = "authState";
const mfaStateKeyName = "mfaState";

export const Persistence = {
    Session: "session", Local: "local",
}

export const MfaTypes = {
    totp: "totp", email: "email", sms: "sms"
}

const successfulHttpThreshold = 299;

export default class AuthClient {
    #clientId;
    #apiHost;
    #persistence;

    constructor(clientId, apiHost) {
        this.#clientId = clientId;
        this.#apiHost = apiHost;
        this.#persistence = Persistence.Session;
    }

    async LogIn(username, password) {
        const resp = await axios.post(`${this.#apiHost}/generateToken`, {
            username: username, password: password, clientId: this.#clientId,
        });

        if (resp?.status < successfulHttpThreshold) {
            if (resp?.data?.enabled) {
                this.#PersistState(null, null, resp.data);
            } else {
                this.#PersistState(resp?.data?.access_token, resp?.data?.refresh_token, null);
            }
        } else {
            throw new Error('Login failed');
        }

        return resp?.data;
    }

    async RefreshToken(refreshToken) {
        const resp = await axios.post(`${this.#apiHost}/refreshToken`, {
            refreshToken: refreshToken,
        });

        if (resp?.status < successfulHttpThreshold) {
            this.#PersistState(resp?.data?.access_token, resp?.data?.refresh_token);
        } else {
            throw new Error('Refresh token failed');
        }

        return resp?.data;
    }

    async EnableMfa(type, identity, token) {
        if (!MfaTypes.hasOwnProperty(type)) {
            throw new Error('Invalid MFA type');
        }

        const resp = await axios.post(`${this.#apiHost}/enableMfa`, {type: type, mfaIdentity: identity}, {
            headers: {Authorization: `${token}`}
        });

        if (resp?.status > successfulHttpThreshold) {
            throw new Error('Enable MFA failed');
        }

        return resp?.data;
    }

    async DisableMfa(code, token) {
        const resp = await axios.post(`${this.#apiHost}/disableMfa`, {code: code}, {
            headers: {Authorization: `${token}`}
        });

        if (resp?.status < successfulHttpThreshold) {
            this.#PersistState(null, null, resp.data);
            return resp?.data;
        } else {
            throw new Error('Mfa disable failed');
        }
    }

    async ValidateMfaCode(code, userId) {
        const resp = await axios.post(`${this.#apiHost}/validateMfa`, {code: code, userId: userId});

        if (resp?.status < successfulHttpThreshold) {
            this.#PersistState(resp?.data?.access_token, resp?.data?.refresh_token, null)
            return resp?.data;
        } else {
            throw new Error('Mfa validation failed');
        }
    }

    async SendMfaCode(userId, token) {
        const resp = await axios.post(`${this.#apiHost}/sendMfaCode`, {userId: userId}, {
            headers: {Authorization: `${token}`}
        });

        if (resp?.status > successfulHttpThreshold) {
            throw new Error('Unable to request for mfa code');
        }

        return resp?.data;
    }

    async ForgotPassword(email) {
        const resp = await axios.post(`${this.#apiHost}/forgotPassword`, {email: email});

        if (resp?.status > successfulHttpThreshold) {
            throw new Error('Mfa validation failed');
        }
    }

    async ResetPassword(newPassword, token) {
        const resp = await axios.post(`${this.#apiHost}/resetPassword`, {token: token, newPassword: newPassword});

        if (resp?.status > successfulHttpThreshold) {
            throw new Error('Reset password failed');
        }
    }

    async ValidateResetPassword(token) {
        const resp = await axios.post(`${this.#apiHost}/validateForgotPassword`, {token: token});

        if (resp?.status > successfulHttpThreshold) {
            throw new Error('Token validation failed');
        }
    }

    async ChangePassword(username, password, newPassword, token) {
        const resp = await axios.post(`${this.#apiHost}/changePassword`, {
            username: username,
            password: password,
            clientId: this.#clientId,
            newPassword: newPassword
        }, {
            headers: {Authorization: `${token}`}
        });

        if (resp?.status > successfulHttpThreshold) {
            throw new Error('Reset password failed');
        }
    }

    setPersistence(persistence) {
        this.#persistence = persistence;
    }

    FindState() {
        let authState = null;
        let mfaState = null;

        let state = sessionStorage.getItem(authStateKeyName);
        if (!state) {
            state = localStorage.getItem(authStateKeyName);
            if (state) {
                this.setPersistence(Persistence.Local);
            }
        }

        let mfa = sessionStorage.getItem(mfaStateKeyName);
        if (!mfa) {
            mfa = localStorage.getItem(mfaStateKeyName);
        }

        if (state) {
            authState = JSON.parse(state);
        }
        if (mfa) {
            mfaState = JSON.parse(mfa);
        }

        return {authState: authState, mfaState: mfaState};
    }

    ClearState() {
        localStorage.removeItem(authStateKeyName);
        sessionStorage.removeItem(authStateKeyName);
        localStorage.removeItem(mfaStateKeyName);
        sessionStorage.removeItem(mfaStateKeyName);
    }

    #PersistState(accessToken, refreshToken, mfaState) {
        switch (this.#persistence) {
            case Persistence.Local:
                this.#SaveStateInStorage(localStorage, accessToken, refreshToken, mfaState);
                break;
            case Persistence.Session:
                this.#SaveStateInStorage(sessionStorage, accessToken, refreshToken, mfaState);
                break;
            default:
                throw new Error('Unknown persistence type');
        }
    }

    #SaveStateInStorage(storage, accessToken, refreshToken, mfaState) {
        if (accessToken || refreshToken) {
            storage.setItem(authStateKeyName, JSON.stringify({
                access_token: accessToken,
                refresh_token: refreshToken
            }));
        }
        if (mfaState) {
            storage.setItem(mfaStateKeyName, JSON.stringify(mfaState));
        }
    }
}
