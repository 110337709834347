import {Box, Card, CardContent, IconButton, Typography, useTheme} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {useEffect, useState} from "react";
import useMfa from "@auth/useMfa";
import {useNavigate} from "react-router-dom";
import {ROUTE_NAMES, ROUTES} from "../../utils/constants";
import {MfaTypes} from "../../auth/authClient";

const SecuritySettings = () => {
    const theme = useTheme();
    const {mfaState} = useMfa();
    const [statusText, setStatusText] = useState("Currently disabled");
    const navigate = useNavigate()

    const handleChangePassword = () => {
        navigate(ROUTES[ROUTE_NAMES.CHANGE_PASSWORD])
    };

    const handleMfaSetup = () => {
        navigate(ROUTES[ROUTE_NAMES.MFA_SETUP])
    };

    useEffect(() => {
        if (mfaState && mfaState.enabled) {
            switch (mfaState.type) {
                case MfaTypes.email:
                    setStatusText("Currently enabled using email verification")
                    break;
                case MfaTypes.totp:
                    setStatusText("Currently enabled using authenticator app")
                    break;
                default:
                    setStatusText("Currently enabled")
            }
        }
    }, [mfaState])

    return (
        <Box textAlign="left">
            <Typography variant="h4" fontWeight="bold">
                Sign-in and Security
            </Typography>
            <Typography variant="h6" color={theme.palette["auxiliar"]} mb={4} fontSize={18}>
                Update your password and set up multi-factor authentication to your account for extra security
            </Typography>
            <Card className="card-user-profile">
                <CardContent>
                    <Typography variant="h5" fontWeight="bold" mb={1} fontSize={21}>
                        Current Password
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mt={3}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1">Click here to change your password</Typography>
                        </Box>
                        <IconButton onClick={() => handleChangePassword()} aria-label="next">
                            <KeyboardArrowRightIcon/>
                        </IconButton>
                    </Box>
                </CardContent>
            </Card>
            <Card className="card-user-profile">
                <CardContent>
                    <Typography variant="h5" fontWeight="bold" mb={1} fontSize={21}>
                        Multi-Factor Authentication
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mt={3}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1">{statusText}</Typography>
                        </Box>
                        <IconButton onClick={() => handleMfaSetup()} aria-label="next">
                            <KeyboardArrowRightIcon/>
                        </IconButton>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default SecuritySettings;
