import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import GroupIcon from "@mui/icons-material/Group";
import LogoutIcon from "@mui/icons-material/Logout";
import PasswordIcon from "@mui/icons-material/Password";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { noop } from "@utils/constants";
import { useMediaQuery, useTheme } from "@mui/material";

const UserMenu = ({ username = "", role = "", logout = noop }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.up("md"));
    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = useState(null);
    const isOpen = Boolean(anchorElUser);
    const [options, setOptions] = useState([]);
    const settings = useMemo(
        () => [
            {
                label: "User Profile",
                icon: <GroupIcon fontSize="small" />,
                roles: ["admin"],
                handler: () => navigate("/settings/user-profile"),
            },
            {
                label: "Sign-in and Security",
                icon: <PasswordIcon fontSize="small"/>,
                roles: ["admin"],
                handler: () => navigate("/settings/security"),
            },
            {
                label: "Logout",
                icon: <LogoutIcon fontSize="small" />,
                roles: ["admin"],
                handler: logout,
            },
        ],
        [logout, navigate]
    );

    useEffect(() => {
        const listMenu = settings.filter((setting) => setting.roles.includes(role));
        setOptions(listMenu);
    }, [role, settings]);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleCustomItems = (setting) => {
        handleCloseUserMenu();
        setting.handler();
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open menu">
                <IconButton onClick={handleOpenUserMenu} sx={{ borderRadius: "5%", padding: "0.15rem" }}>
                    <AccountCircleIcon fontSize="large" />
                    {isMobile && (
                        <Typography variant="body1" ml={1}>
                            {username}
                        </Typography>
                    )}
                    {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={isOpen}
                onClose={handleCloseUserMenu}
            >
                {options.map((setting) => (
                    <MenuItem key={setting.label} onClick={() => handleCustomItems(setting)}>
                        <ListItemIcon>{setting.icon}</ListItemIcon>
                        <Typography textAlign="center">{setting.label}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default UserMenu;
