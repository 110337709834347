import {useContext} from "react";
import {AuthContext} from "./context";

export default function useMfa() {
    const context = useContext(AuthContext)

    if (!context) {
        throw new Error('useAuth must be used within a AuthProvider')
    }
    return {
        enableMfa: context.enableMfa,
        validateMfa: context.validateMfa,
        sendMfaCode: context.sendMfaCode,
        mfaState: context.mfaState,
        disableMfa: context.disableMfa
    }
}