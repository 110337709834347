import {Button, Card, CardContent, Grid, InputLabel, TextField, Typography, useTheme} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React, {useState} from "react";
import useMfa from "../../auth/useMfa";
import {useNavigate} from "react-router-dom";
import MfaChallenge from "./mfaChallenge";
import useUser from "../../auth/useUser";
import {MfaTypes} from "../../auth/authClient";
import {ToastTypes, useToast} from "./memberToast";

const titles = {
    [MfaTypes.email]: "Authenticate via email ",
    [MfaTypes.sms]: "Authenticate via sms codes ",
}

const subTitles = {
    [MfaTypes.email]: "Enter the email address in which you want to receive your verification codes",
    [MfaTypes.sms]: "Enter the phone number in which you want to receive your verification codes"
}

const contactMethods = {
    [MfaTypes.email]: "Email address",
    [MfaTypes.sms]: "Phone number"
}

const TwoStepMethodSetup = (props = MfaTypes.email) => {
    const [setupCodeSent, setSetupCodeSent] = useState(false)
    const [method, setMethod] = useState("")
    const [error, setError] = useState(null)
    const {sendMfaCode, enableMfa} = useMfa()
    const {oid} = useUser()
    const theme = useTheme()
    const navigate = useNavigate()
    const title = titles[props.method]
    const subTitle = subTitles[props.method]
    const contactMethod = contactMethods[props.method]
    const [loading, setLoading] = useState(false)
    const {showToast} = useToast()

    const handleSendCode = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            await enableMfa(props.method, method)
            await sendMfaCode(oid)
            setSetupCodeSent(true)
            showToast("Code sent", ToastTypes.WHITE_SUCCESS)
        } catch (e) {
            setError("Something went wrong")
        } finally {
            setLoading(false)
        }
    }

    const sendCodeForm = () => {
        return (
            <form onSubmit={handleSendCode}>
                <Grid container flexDirection="column" spacing={2}>
                    <Grid item xs={6}>
                        <InputLabel htmlFor="method">{contactMethod}</InputLabel>
                        <TextField
                            margin="dense"
                            value={method}
                            error={!!error}
                            helperText={error}
                            onChange={(e) => setMethod(e.target.value)}
                            onFocus={() => setError(null)}
                            fullWidth
                            id="method"
                            name="method"
                            type="text"
                        />
                    </Grid>
                    <Grid container flexDirection="row" spacing={2} justifyContent="flex-end">
                        <Grid item>
                            <Button variant="outlined" size={"large"} onClick={() => navigate('/settings/security')}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <LoadingButton variant="contained" size={"large"} type="submit" loading={loading}>
                                Send code
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        )
    }

    return (
        <Card className="card-user-profile">
            <CardContent>
                <Typography variant="h5" fontWeight="bold">
                    {title}
                </Typography>
                <Typography variant="h6" color={theme.palette["auxiliar"]} mb={4} fontSize={18}>
                    {subTitle}
                </Typography>
                {
                    !setupCodeSent ?
                        sendCodeForm() :
                        <MfaChallenge messageOnSuccess={`${contactMethod} authentication successfully set up`}
                                      navigateTo={"/settings/security"}/>
                }
            </CardContent>
        </Card>
    )
}

export default TwoStepMethodSetup