export const entitlements = {
    BLOCKS: "OneDigitalClientBlocks", // for `My Financial Plan` Card
    UPCOMING_GOALS: "OneDigitalUpcomingGoalsBlock",
    RETIREMENT_FUND: "OneDigitalRetirementFundBlock",
    EMERGENCY_FUND: "OneDigitalEmergencyFundBlock",
    HEALTH_SAVINGS: "OneDigitalHealthSavingsBlock",
    SOCIAL_SECURITY: "OneDigitalSocialSecurityBlock",
    INFLATION: "OneDigitalInflationBlock",
    CREDIT_CARDS: "OneDigitalCreditCardsV3Block",
    STUDENT_LOANS: "OneDigitalStudentLoansBlock",
};

export const ITEMS = [
    {
        title: "Determine monthly savings for pre-retirement goals that are less than 10 years in the future.",
        thumb: "https://d365mock.blob.core.windows.net/assets/Upcoming%20Goals.jpg",
        entitlement: entitlements.UPCOMING_GOALS,
        alt: "Upcoming Goals",
    },
    {
        title: "Are you taking advantage of your employer-sponsored retirement plan?",
        thumb: "https://d365mock.blob.core.windows.net/assets/Retirement%20Fund.jpg",
        entitlement: entitlements.RETIREMENT_FUND,
        alt: "Retirement Fund",
    },
    {
        title: "Establish a solid foundation of financial wellness by building your emergency fund.",
        thumb: "https://d365mock.blob.core.windows.net/assets/Emergency%20Fund.jpg",
        entitlement: entitlements.EMERGENCY_FUND,
        alt: "Emergency Fund",
    },
    {
        title: "Are you taking advantage of your health savings account?",
        thumb: "https://d365mock.blob.core.windows.net/assets/Health%20Savings%20Account.jpg",
        entitlement: entitlements.HEALTH_SAVINGS,
        alt: "Health Savings Account",
    },
    {
        title: "Ensure you don’t get less than you’re entitled to.",
        thumb: "https://d365mock.blob.core.windows.net/assets/Social%20Security.jpg",
        entitlement: entitlements.SOCIAL_SECURITY,
        alt: "Social Security",
    },
    {
        title: "History shows us how rising costs affect how far your savings will go.",
        thumb: "https://d365mock.blob.core.windows.net/assets/Inflation.jpg",
        entitlement: entitlements.INFLATION,
        alt: "Inflation",
    },
    {
        title: "Compare options and see how long it will it take to pay off your credit cards.",
        thumb: "https://d365mock.blob.core.windows.net/assets/Credit%20Cards.jpg",
        entitlement: entitlements.CREDIT_CARDS,
        alt: "Credit Cards",
    },
    {
        title: "See how long it will take to pay off your student loans.",
        thumb: "https://d365mock.blob.core.windows.net/assets/Student%20Loans.jpg",
        entitlement: entitlements.STUDENT_LOANS,
        alt: "Student Loans",
    },
];
