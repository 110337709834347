import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { ColoredText, FormBox } from "../pages/Onboarding/styles";
import logo from "@assets/od-logo.png";
import { useNavigate } from "react-router-dom";

const LayoutForm = ({ children, title, secondText, actionText, actionLink }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(1200), { noSsr: true });
    const navigate = useNavigate();

    const redirection = (page) => {
        if (!page) return;
        navigate(page);
    };

    return (
        <FormBox>
            {!isMobile && (
                <>
                    <Box
                        component="img"
                        src={logo}
                        cursor="pointer"
                        width={theme.spacing(28)}
                        height={theme.spacing(5.2)}
                        alt="od thumb"
                        mb={theme.spacing(2.8)}
                    />
                    <ColoredText variant="h3" component="h1" mb={7.5} align="justify" fontWeight="900">
                        The pursuit of your best financial life starts here.
                    </ColoredText>
                </>
            )}
            <Typography variant="h4" mb={1} align="left" fontWeight="900">
                {title}
            </Typography>
            {secondText ? (
                <Typography variant="body1" align="left">
                    {secondText}
                    {actionText ? (
                        <span className="link fw-heavy pointer" onClick={() => redirection(actionLink)}>
                            {actionText}
                        </span>
                    ) : null}
                </Typography>
            ) : null}
            {children}
            {!isMobile && (
                <Typography variant="body1" align="left" mt={4}>
                    Investment advice is offered through OneDigital Investment Advisors, an SEC-registered investment
                    adviser and wholly owned subsidiary of OneDigital.
                </Typography>
            )}
        </FormBox>
    );
};

export default LayoutForm;