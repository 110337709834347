import { Grid, Typography } from "@mui/material";
import React from "react";

const Row = ({ cols }) => {
    return (
        <Grid container py={2}>
            {cols.map((col, index) => (
                <Grid item xs={12} md={col.mdSize ?? 12 / cols.length} key={index}>
                    <Typography variant="subtitle" fontWeight={index === 0 ? "bold" : "normal"}>
                        {col.text}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
};

export default Row;
