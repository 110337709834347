import React from "react";
import { useMediaQuery, Grid, ListItemButton, ListItemText, ListItemIcon, List, useTheme, Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { settingsMenu } from "@utils/constants";
import useSettingMenu from "@hooks/useSettingMenu";

const Settings = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const { current, handleChange } = useSettingMenu();

    return (
        <Box minHeight={"100%"} pt={17}>
            <Grid container spacing={4}>
                {!isMobile && (
                    <Grid item xs={12} md={3}>
                        <List>
                            {settingsMenu.map((option, indexOpt) => (
                                <ListItemButton
                                    selected={option.label === current.label}
                                    onClick={() => handleChange(option)}
                                    key={`${option.label}-${indexOpt}`}
                                >
                                    <ListItemIcon>{option.icon}</ListItemIcon>
                                    <ListItemText
                                        primary={option.label}
                                        primaryTypographyProps={{
                                            fontWeight: current === indexOpt ? "bold" : "normal",
                                            fontSize: 18,
                                        }}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    </Grid>
                )}
                <Grid item xs={12} md={9} mt={1}>
                    <Outlet />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Settings;
