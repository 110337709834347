import { Typography, Box, styled, Checkbox } from "@mui/material";
import PhoneInput from "react-phone-input-2";

export const Layout = styled("div")(({ theme }) => ({
    display: "flex",
    height: "100%",
    "&::before": {
        content: '""',
        position: "absolute",
        width: "100%",
        height: "0.5rem",
        background: theme.palette.extras.bgLinear,
    },
}));

export const ImgBox = styled("div")(({ theme }) => ({
    backgroundImage: `url(${process.env.REACT_APP_URL_BG})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    flex: 1,
    display: "none",
    [theme.breakpoints.up("lg")]: {
        display: "block",
    },
}));

export const BodyDiv = styled("div")(({ theme }) => ({
    overflowY: "auto",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
        maxWidth: "41.8125rem",
    },
    [theme.breakpoints.up("xl")]: {
        width: "40%",
        maxWidth: "none",
    },
}));

export const BodyBox = styled(Box)(({ theme }) => ({
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(8, 4),
    [theme.breakpoints.up("md")]: {
        padding: theme.spacing(8),
    },
}));

export const FormBox = styled("div")(({ theme }) => ({
    margin: "0 auto",
    maxWidth: "41.8125rem",
    [theme.breakpoints.up("xl")]: {
        maxWidth: "100%",
    },
}));

export const ColoredText = styled(Typography)(({ theme }) => ({
    background: theme.palette.extras.colorLiner,
    backgroundColor: theme.palette.blue.main,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "bolder",
    fontSize: "2.3125rem",
    letterSpacing: "-3px",
    fontFamily: theme.typography.fontFamilyArial,
}));

export const RulesBox = styled(Box)(({ theme }) => ({
    width: "100%",
    backgroundColor: "white",
    px: 3,
    py: 1,
    [theme.breakpoints.up(900)]: {
        position: "absolute",
        top: "100%",
        left: "0",
        zIndex: 3,
        boxShadow: 3,
    },
}));

export const InputPhone = styled(PhoneInput)(({ theme }) => ({
    ...theme.components.MuiOutlinedInput.defaultProps,
    height: "3.5rem",
    marginTop: "0.5rem",
    marginBottom: "0.25rem",
    "& .form-control": {
        padding: "1.03rem 0.875rem",
        width: "100%",
        height: "100%",
    },
    "& .flag-dropdown": {
        display: "none",
    },
}));

export const CustomInputPhone = styled(InputPhone, {
    shouldForwardProp: (prop) => !["error"].includes(prop),
})(({ error }) => ({
    "& .form-control": {
        padding: "1.03rem 0.875rem",
        width: "100%",
        height: "100%",
        border: error ? "1px solid crimson" : "",
    },
}));

export const CustomCheckBox = styled(Checkbox, {
    shouldForwardProp: (prop) => !["type", "isIntern"].includes(prop),
})(({ type, isIntern }) => ({
    color: type === "error" ? "crimson" : "",
    ...(isIntern && {
        padding: 0,
        paddingRight: "1rem",
    }),
}));
