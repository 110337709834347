import {useContext} from 'react'
import {AuthContext} from "./context";

export default function useAuth() {
    const context = useContext(AuthContext)

    if (!context) {
        throw new Error('useAuth must be used within a AuthProvider')
    }
    return {
        logIn: context.logIn,
        logOut: context.logOut,
        loggedIn: context.loggedIn,
        forgotPassword: context.forgotPassword,
        resetPassword: context.resetPassword,
        validateResetPassword: context.validateResetPassword,
        changePassword: context.changePassword,
        working: context.working
    }
}