import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
    initRules,
    initRulesOther,
    regexLowerCase,
    regexNumber,
    regexUpperCase,
    statesRules
} from "../Onboarding/constants";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {Rules} from "../Onboarding/components";
import {useNavigate} from "react-router-dom";
import useUser from "../../auth/useUser";
import useAuth from "../../auth/useAuth";
import {ToastTypes, useToast} from "../Components/memberToast";

const formInit = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
}

const errorsInit = {
    currentPassword: null,
    newPassword: null,
    confirmPassword: null
}

const fields = {
    CURRENT_PASSWORD: "currentPassword",
    NEW_PASSWORD: "newPassword",
    CONFIRM_PASSWORD: "confirmPassword"
}

const showPasswordInit = {
    currentPassword: false,
    newPassword: false,
    confirmPassword: false
}

const focusInit = {
    currentPassword: false,
    newPassword: false,
    confirmPassword: false
}

const errorMessages = {
    [fields.CURRENT_PASSWORD]: "Enter your current password",
    [fields.NEW_PASSWORD]: "Create your password",
    [fields.CONFIRM_PASSWORD]: "Confirm your password"
}


const ChangePassword = () => {
    const theme = useTheme();
    const [form, setForm] = useState(formInit)
    const [errors, setErrors] = useState(errorsInit)
    const [showPassword, setShowPassword] = useState(showPasswordInit)
    const [rules, setRules] = useState(initRules)
    const [rulesOther, setRulesOther] = useState(initRulesOther)
    const validations = {
        [fields.CURRENT_PASSWORD]: (value) => value.length < 1,
        [fields.NEW_PASSWORD]: (value) => !value || !rules.every((rule) => rule.state.name === statesRules.done.name),
        [fields.CONFIRM_PASSWORD]: (value) => value.length < 8
    }
    const [focus, setFocus] = useState(focusInit);
    const navigate = useNavigate();
    const {emails} = useUser();
    const email = emails[0];
    const {changePassword} = useAuth()
    const {showToast} = useToast();

    const handleErrorField = (field, hasError) => {
        if (hasError) {
            setErrors((prev) => ({...prev, [field]: errorMessages[field]}));
        }
        if (!hasError && errors[field]) {
            setErrors((prev) => {
                const copyErrors = {...prev};
                delete copyErrors[field];
                return copyErrors;
            });
        }
    };

    const toggleShowPassword = (field) => {
        setShowPassword((prevState) => ({...prevState, [field]: !prevState[field]}))
    }

    const handleFocus = (field) => {
        setFocus(
            (prevState) => (
                {...prevState, [field]: true}
            ))
    }

    const handleBlur = ({target}) => {
        const field = target.name;
        const value = form[field]
        const hasError = validations[field](value);
        if (focus[target.name]) {
            setFocus((prevState) => ({
                ...prevState, [field]: false
            }))
        }
        handleErrorField(field, hasError);
    };

    const handleChange = ({target}) => {
        const {name, value} = target;
        setForm({...form, [name]: value});
    };

    const getPswTypeField = (field) => {
        return showPassword[field] ? "text" : "password"
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const hasErrors = Object.keys(validations).some((field) => validations[field](form[field]));
        if (hasErrors) {
            return
        }

        try {
            await changePassword(email, form.currentPassword, form.newPassword)
            navigate("/settings/security")
            showToast("Password changed successfully", ToastTypes.WHITE_SUCCESS)
        } catch {
            showToast("Something went wrong, please try again later")
        }
    }

    useEffect(() => {
        const getStatesRules = (isTrue) => (isTrue ? statesRules.done : statesRules.wrong);

        const validatePsw = (val) => {
            const objRules = {
                0: getStatesRules(regexLowerCase.test(val)),
                1: getStatesRules(regexUpperCase.test(val)),
                2: getStatesRules(regexNumber.test(val)),
                3: getStatesRules(val.length >= 8),
            };
            setRules((prev) => prev.map((rule, index) => ({...rule, state: objRules[index]})));
        };

        const validatePswOther = (val) => {
            setRulesOther((prev) => prev.map((rule) => ({...rule, state: getStatesRules(form.newPassword === val)})));
        };

        validatePsw(form.newPassword);
        validatePswOther(form.confirmPassword);
    }, [form.newPassword, form.confirmPassword]);

    return (
        <Box textAlign="left">
            <Typography variant="h4" fontWeight="bold">
                Change Password
            </Typography>
            <Typography variant="h6" color={theme.palette["auxiliar"]} mb={4} fontSize={18}>
                Update your account's password
            </Typography>
            <Card className="card-user-profile">
                <CardContent>
                    <Grid container flexDirection="column" spacing={2}>
                        <form onSubmit={handleSubmit}>
                            <Grid item xs={6}>
                                <InputLabel>Current Password</InputLabel>
                                <TextField
                                    margin="dense"
                                    value={form.currentPassword}
                                    error={!!errors[fields.CURRENT_PASSWORD]}
                                    helperText={errors[fields.CURRENT_PASSWORD]}
                                    fullWidth
                                    onChange={handleChange}
                                    id={fields.CURRENT_PASSWORD}
                                    name={fields.CURRENT_PASSWORD}
                                    onBlur={handleBlur}
                                    onFocus={() => handleErrorField(fields.CURRENT_PASSWORD, false)}
                                    type={getPswTypeField(fields.CURRENT_PASSWORD)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => toggleShowPassword(fields.CURRENT_PASSWORD)}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {showPassword.currentPassword ?  <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel>New Password</InputLabel>
                                <TextField
                                    margin="dense"
                                    value={form.newPassword}
                                    error={!!errors[fields.NEW_PASSWORD]}
                                    helperText={errors[fields.NEW_PASSWORD]}
                                    fullWidth
                                    id={fields.NEW_PASSWORD}
                                    name={fields.NEW_PASSWORD}
                                    type={getPswTypeField(fields.NEW_PASSWORD)}
                                    onChange={handleChange}
                                    onFocus={() => {
                                        handleErrorField(fields.NEW_PASSWORD, false);
                                        handleFocus(fields.NEW_PASSWORD)
                                    }}
                                    onBlur={(_) => {
                                        handleBlur({target: {name: fields.NEW_PASSWORD}});
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => toggleShowPassword(fields.NEW_PASSWORD)}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {showPassword.newPassword ? <VisibilityOff/> : <Visibility/> }
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Rules rules={rules}/>
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel>Confirm New Password</InputLabel>
                                <TextField
                                    margin="dense"
                                    value={form.confirmPassword}
                                    error={!!errors[fields.CONFIRM_PASSWORD]}
                                    helperText={errors[fields.CONFIRM_PASSWORD]}
                                    fullWidth
                                    id={fields.CONFIRM_PASSWORD}
                                    name={fields.CONFIRM_PASSWORD}
                                    type={getPswTypeField(fields.CONFIRM_PASSWORD)}
                                    onChange={handleChange}
                                    onFocus={() => {
                                        handleFocus(fields.CONFIRM_PASSWORD)
                                        handleErrorField(fields.CONFIRM_PASSWORD, false);
                                    }}
                                    onBlur={() => {
                                        handleBlur({target: {name: fields.CONFIRM_PASSWORD}});
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => toggleShowPassword(fields.CONFIRM_PASSWORD)}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {showPassword.confirmPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {focus.confirmPassword && <Rules rules={rulesOther}/>}
                            </Grid>
                            <Grid container flexDirection="row" spacing={2} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="outlined" size={"large"}
                                            onClick={() => navigate('/settings/security')}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" size={"large"} type={'submit'}>
                                        Save Changes
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
};

export default ChangePassword;
