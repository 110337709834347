import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

import React, {useState} from "react";

import "./memberToast.css";

const ToastContext = React.createContext(null);

export const MemberToastProvider = ({children}) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [type, setType] = useState(ToastTypes.ERROR);
    const [currentTimeout, setCurrentTimeout] = useState(null);

    const close = () => {
        setOpen(false);
    }

    const showToast = (message, type = ToastTypes.ERROR) => {
        setOpen(true);
        setMessage(message);
        setType(type);
        if (currentTimeout) {
            clearTimeout(currentTimeout);
        }
        setCurrentTimeout(setTimeout(() => {
            close();
        }, 8000))
    }

    const {backgroundColor, color, icon: Icon, borderColor} = TypeStyles[type];
    const style = borderColor ? {
        border: `1px solid ${borderColor}`,
        backgroundColor: backgroundColor
    } : {backgroundColor: backgroundColor};
    return (
        <ToastContext.Provider value={{showToast}}>
            <div style={style}
                 className={`outerMemberToastContainer ${open ? 'memberToastContainerOpen' : ''}`}>
                <div className="memberToastContainer">
                    <Icon style={{color}}/>
                    <div style={{color}}>{message}</div>
                </div>
                <CloseIcon className="closeIcon" style={{color}} onClick={() => close()}/>
            </div>
            {children}
        </ToastContext.Provider>
    )
}

export const useToast = () => {
    const context = React.useContext(ToastContext);
    if (context === undefined) {
        throw new Error(`useToast must be used within a ToastProvider`)
    }
    return context;
}

export const ToastTypes = {
    SUCCESS: "success",
    ERROR: "error",
    WHITE_SUCCESS: "white_success",
}

export const TypeStyles = {
    [ToastTypes.SUCCESS]: {
        backgroundColor: "#E7F5C4",
        color: "#2E7D32",
        icon: CheckCircleOutlineIcon
    },
    [ToastTypes.ERROR]: {
        backgroundColor: "#F6E2E1",
        color: "#C23F38",
        icon: ErrorOutlineIcon
    },
    [ToastTypes.WHITE_SUCCESS]: {
        backgroundColor: "#FFFFFF",
        color: "#2E7D32",
        borderColor: "#2E7D32",
        icon: CheckCircleOutlineIcon
    }
}