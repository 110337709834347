import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const Notification = ({ open, severity, onClose, message, autoHideDuration }) => {
    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
            <Alert severity={severity} variant="filled">
                {message}
            </Alert>
        </Snackbar>
    );
};

export default Notification;
