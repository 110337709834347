import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    TextField
} from "@mui/material";
import LayoutForm from "@components/layoutForm";
import {BodyBox, BodyDiv, ImgBox, Layout} from "../Onboarding/styles";
import {useNavigate, useSearchParams} from "react-router-dom";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {ROUTE_NAMES, ROUTES} from "../../utils/constants";
import useAuth from "@auth/useAuth";

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const hasInviteCode = !!token;
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const passwordEquals = password === confirmPassword;
    const {resetPassword, validateResetPassword} = useAuth()
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!hasInviteCode) {
            navigate(ROUTES[ROUTE_NAMES.LOGIN]);
        }
    }, [hasInviteCode, navigate]);

    useEffect(() => {
        const validate = async () => {
            if (token) {
                try {
                    await validateResetPassword(token);
                } catch {
                    setError(true)
                } finally {
                    setLoading(false)
                }
            }
        }
        validate();
    }, [token]);

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const onChangeConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    };

    const getPswTypeField = (show) => (show ? "text" : "password");

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (passwordEquals) {
            await resetPassword(password, token)
            navigate(ROUTES[ROUTE_NAMES.LOGIN]);
        }
    };

    const renderBody = () => {
        if (loading) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
                    <CircularProgress/>
                </Box>
            )
        }
        if (error) {
            return navigate(ROUTES[ROUTE_NAMES.LOGIN], {
                state: {
                    toast: {
                        message: "Your password reset link has expired. Please request a new link to recover access to your OneDigital account."
                    }
                }
            });
        }
        return (
            <LayoutForm title="Reset your password">
                <form onSubmit={handleSubmit}>
                    <Grid container mt={3} spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="password">New Password *</InputLabel>
                            <TextField
                                margin="dense"
                                value={password}
                                error={!passwordEquals}
                                helperText={!passwordEquals ? "Please enter the same password" : ""}
                                fullWidth
                                id="password"
                                name="password"
                                type={getPswTypeField(showPassword)}
                                onChange={onChangePassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="confirmPassword">Confirm Password *</InputLabel>
                            <TextField
                                margin="dense"
                                value={confirmPassword}
                                error={!passwordEquals}
                                helperText={!passwordEquals ? "Please enter the same password" : ""}
                                fullWidth
                                id="confirmPassword"
                                name="confirmPassword"
                                type={getPswTypeField(showConfirmPassword)}
                                onChange={onChangeConfirmPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container mt={3} spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                size="large"
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={!passwordEquals}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm>
        )
    }

    return (
        <Layout>
            <BodyDiv>
                <BodyBox>
                    {renderBody()}
                </BodyBox>
            </BodyDiv>
            <ImgBox/>
        </Layout>
    );
};

export default ResetPassword;
