import { Stack, Tooltip, useTheme } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";

const TextInfo = ({ children, tootltipMsg }) => {
    const theme = useTheme();
    return (
        <Stack direction="row" alignItems="center" gap={1}>
            {children}
            <Tooltip title={tootltipMsg}>
                <InfoOutlinedIcon fontSize={theme.spacing(2)} cursor="pointer" />
            </Tooltip>
        </Stack>
    );
};

export default TextInfo;
