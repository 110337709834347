import React, {useEffect, useState} from "react";
import useAuth from "@auth/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Checkbox,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LayoutForm from "@components/layoutForm";
import useMfa from "../../auth/useMfa";
import MfaChallenge from "../Components/mfaChallenge";
import {MfaTypes} from "../../auth/authClient";
import {ToastTypes, useToast} from "../Components/memberToast";


const Layout = styled("div")(({theme}) => ({
    display: "flex",
    height: "100%",
    "&::before": {
        content: '""',
        position: "absolute",
        width: "100%",
        height: "0.5rem",
        background: theme.palette.extras.bgLinear,
    },
}));

const ImgBox = styled("div")(({theme}) => ({
    backgroundImage: `url("https://d365mock.blob.core.windows.net/b2ctemplates/singin/assets/Group%201.jpg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    flex: 1,
    display: "none",
    [theme.breakpoints.up("lg")]: {
        display: "block",
    },
}));

const BodyDiv = styled("div")(({theme}) => ({
    overflowY: "auto",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
        maxWidth: "41.8125rem",
    },
}));

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isRememberMe, setIsRememberMe] = useState(false);
    const navigate = useNavigate();
    const {logIn, loggedIn, working} = useAuth();
    const [mfaChallenge, setMfaChallenge] = useState(false);
    const {sendMfaCode} = useMfa();
    const {state} = useLocation();
    const {showToast} = useToast();
    const [loading, setLoading] = useState(false)

    const redirectRecoverPassword = () => {
        navigate("/recover-password");
    };

    useEffect(() => {
        if (!working && loggedIn) {
            navigate("/");
        }
    }, [loggedIn, working]);

    useEffect(() => {
        if (state && state.toast) {
            showToast(state.toast);
        }
    }, [state]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            showToast("Please enter your email or password");
            return;
        }

        try {
            setLoading(true)
            const result = await logIn(email, password, isRememberMe);
            if (result && result.enabled) {
                if (result.type !== MfaTypes.totp) {
                    sendMfaCode(result.userId);
                    showToast("Code sent", ToastTypes.WHITE_SUCCESS)
                }
                setMfaChallenge(true);
            } else {
                navigate("/");
            }
        } catch (_) {
            showToast("Failed to log in");
        } finally {
            setLoading(false)
        }
    };

    return (
        <Layout>
            <BodyDiv>
                <Box p={8} minHeight={"100%"} display={"flex"} alignItems={"center"} justifyContent="flex-end">
                    <LayoutForm
                        title="Sign in to your account"
                        secondText="Need an account? "
                        actionText="Register here"
                        actionLink="/onboarding"
                    >
                        {mfaChallenge ? (
                            <Box flex flexDirection="column" spacing={2} mt={3} mb={10}>
                                <MfaChallenge/>
                            </Box>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <Grid container mt={3} spacing={2}>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="email">Email *</InputLabel>
                                        <TextField
                                            margin="dense"
                                            value={email}
                                            fullWidth
                                            onChange={(e) => setEmail(e.target.value)}
                                            id="email"
                                            name="email"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="password">Password *</InputLabel>
                                        <TextField
                                            margin="dense"
                                            value={password}
                                            fullWidth
                                            onChange={(e) => setPassword(e.target.value)}
                                            id="password"
                                            name="password"
                                            type={showPassword ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            onMouseDown={(e) => e.preventDefault()}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container mt={3} alignItems="center" justifyContent="space-between">
                                    <Box display="flex" alignItems="center">
                                        <Checkbox
                                            checked={isRememberMe}
                                            onChange={(e) => {
                                                setIsRememberMe(e.target.checked);
                                            }}
                                        />
                                        <Typography variant="body1" align="left">
                                            Remember me
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <span className="link fw-heavy pointer" onClick={redirectRecoverPassword}>
                                            Forgot Password?
                                        </span>
                                    </Box>
                                </Grid>
                                <Grid container mt={3} spacing={2}>
                                    <Grid item xs={12}>
                                        <Button
                                            fullWidth
                                            size="large"
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            disabled={loading}
                                        >
                                            Sign in
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </LayoutForm>
                </Box>
            </BodyDiv>
            <ImgBox/>
        </Layout>
    );
};

export default Login;
