import {
    Box,
    Card,
    CardContent,
    IconButton,
    Typography,
    useTheme
} from "@mui/material";
import QrCodeIcon from '@mui/icons-material/QrCode';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import React, {useEffect, useState} from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import useMfa from "../../auth/useMfa";
import QRCode from "react-qr-code";
import useUser from "../../auth/useUser";
import MfaChallenge from "../Components/mfaChallenge";
import TwoStepMethodSetup from "../Components/twoStepMethodSetup";
import {MfaTypes} from "../../auth/authClient";
import {ToastTypes, useToast} from "../Components/memberToast";

const disabledText = {
    email: "Verification codes will be sent to your desired email address",
    totp: "Single use verification codes will be generated using an authenticator app such as Google Authenticator or Microsoft Authenticator",
    sms: "Verification codes will be sent to your desired phone number via SMS"
}

const MfaSetup = () => {
    const theme = useTheme();
    const {mfaState, enableMfa, sendMfaCode} = useMfa()
    const {emails, oid} = useUser()
    const {showToast} = useToast()
    const [statusTexts, setStatusTexts] = useState({
        email: disabledText.email,
        totp: disabledText.totp,
        sms: disabledText.sms
    })
    const [mfaConfig, setMfaConfig] = useState(null)
    const [mfaActions, setMfaActions] = useState({
        email: async () => {
            setMfaConfig({type: MfaTypes.email})
        },
        totp: async () => {
            const result = await enableMfa(MfaTypes.totp, emails[0])
            setMfaConfig(result)
        },
        sms: async () => {
            setMfaConfig({type: MfaTypes.sms})
        }
    })
    const [disabling, setDisabling] = useState(false)

    useEffect(() => {
        if (mfaState && mfaState.enabled) {
            switch (mfaState.type) {
                case MfaTypes.email:
                    setStatusTexts((prevState) => {
                        return {
                            ...prevState,
                            email: "Currently enabled using email verification",
                        }
                    })
                    setMfaActions((prevState) => {
                        return {
                            ...prevState,
                            email: async () => {
                                sendMfaCode(oid)
                                showToast("Code sent", ToastTypes.WHITE_SUCCESS)
                                setDisabling(true)
                            }
                        }
                    })
                    break;
                case MfaTypes.totp:
                    setStatusTexts((prevState) => {
                        return {
                            ...prevState,
                            totp: "Currently enabled using authenticator app"
                        }
                    })
                    setMfaActions((prevState) => {
                        return {
                            ...prevState,
                            totp: async () => {
                                setDisabling(true)
                            }
                        }
                    })
                    break;
                case MfaTypes.sms:
                    setStatusTexts((prevState) => {
                        return {
                            ...prevState,
                            sms: "Currently enabled using SMS",
                        }
                    })
                    setMfaActions((prevState) => {
                        return {
                            ...prevState,
                            sms: async () => {
                                sendMfaCode(oid)
                                showToast("Code sent", ToastTypes.WHITE_SUCCESS)
                                setDisabling(true)
                            }
                        }
                    })
                    break;
                default:
                    setStatusTexts({
                        email: disabledText.email,
                        totp: disabledText.totp,
                        sms: disabledText.sms
                    })
            }
        }
    }, [mfaState])

    const configElement = (title, subtitle, icon, action) => {
        const ConfigIcon = icon
        const handleClick = async () => {
            await action()
        }
        return (
            <Card className="card-user-profile">
                <CardContent>
                    <Box display="flex" alignItems="center" flexDirection="row" gap={3}>
                        <ConfigIcon/>
                        <Box display="flex" flexDirection="column" flexGrow={1}>
                            <Typography variant="h5" fontWeight="bold" mb={1} fontSize={21}>
                                {title}
                            </Typography>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mt={3}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body1">{subtitle}</Typography>
                                </Box>
                                <IconButton onClick={handleClick} aria-label="next">
                                    <KeyboardArrowRightIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>

                </CardContent>
            </Card>
        )
    }

    const itemList = () => {
        return (
            <>
                {configElement("Authenticate via SMS", statusTexts.sms, SmsIcon, mfaActions.sms)}
                {configElement("Authenticate via email", statusTexts.email, EmailIcon, mfaActions.email)}
                {configElement("Authenticate via authenticator app", statusTexts.totp, QrCodeIcon, mfaActions.totp)}
            </>
        )
    }

    const configSwitch = () => {
        switch (mfaConfig.type) {
            case MfaTypes.totp:
                return totpSetup()
            case MfaTypes.email:
                return <TwoStepMethodSetup method={MfaTypes.email}/>
            case MfaTypes.sms:
                return <TwoStepMethodSetup method={MfaTypes.sms}/>
            default:
                return itemList()
        }
    }

    const disableMfa = () => {
        return (
            <Card className="card-user-profile">
                <CardContent>
                    <Typography variant="h6" fontWeight="bold">Disabling MFA...</Typography>
                    <MfaChallenge disable={true} navigateTo={"/settings/security"}/>
                </CardContent>
            </Card>
        )
    }

    const body = () => {
        return (
            disabling ? disableMfa() :
                mfaConfig ? configSwitch() : itemList()
        )
    }

    const generateQrCodeValue = (issuer, key, user) => {
        return `otpauth://totp/${user}?secret=${key}&issuer=${issuer}`
    }

    const totpSetup = () => {
        const config = mfaConfig.setup.mfaTotpProvider
        return (
            <Card className="card-user-profile">
                <CardContent>
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap={3}>
                        <Typography variant="h6" fontWeight="bold">
                            Scan this QR code with your authenticator app
                        </Typography>
                        <QRCode value={generateQrCodeValue(config.issuer, config.key, config.username)}/>
                    </Box>
                    <MfaChallenge mt={5} navigateTo={"/settings/security"} messageOnSuccess={"Authentication via single use codes successfully set up"}/>
                </CardContent>
            </Card>
        )
    }

    return (
        <Box textAlign="left">
            <Typography variant="h4" fontWeight="bold">
                Multi-Factor Authentication
            </Typography>
            <Typography variant="h6" color={theme.palette["auxiliar"]} mb={4} fontSize={18}>
                Set up multi-factor authentication to add an extra level of security to your account
            </Typography>
            {body()}
        </Box>
    )
}

export default MfaSetup