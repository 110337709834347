import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import AuthClient, { Persistence } from "./authClient";
import Notification from "../components/notification";
import { resolveErrorMessage } from "../utils/utils";

const AuthContext = createContext(undefined);

function AuthProvider({ config, children }) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [claims, setClaims] = useState(undefined);
    const [child, setChild] = useState(undefined);
    const [mfaState, setMfaState] = useState(undefined);
    const [error, setError] = useState(null);
    const [showError, setShowError] = useState(true);
    const working = useRef(true);
    const axiosInstance = useRef(axios.create());
    const accessToken = useRef(null);
    const refreshToken = useRef(null);
    const client = useRef(null);
    const tokenExpirationEpoch = useRef(null);

    axiosInstance.current.defaults.headers.common["Content-Type"] = "application/json";
    axiosInstance.current.defaults.baseURL = config.apiHost;

    const parseToken = (token) => {
        const base64 = token.split(".")[1];
        return JSON.parse(window.atob(base64));
    };

    const parseAuthResponse = (result) => {
        if (result.access_token) {
            let expiration;
            if (result.expires_in) {
                expiration = Date.now() + result.expires_in * 1000;
            }
            if (result.expires_on) {
                expiration = result.expires_on * 1000;
            }

            accessToken.current = result.access_token;
            refreshToken.current = result.refresh_token;
            tokenExpirationEpoch.current = expiration;
            setClaims(parseToken(result.access_token));
            setLoggedIn(true);
        }
    };

    const logIn = async (email, password, rememberMe = false) => {
        working.current = true;
        if (client.current) {
            if (rememberMe) {
                client.current.setPersistence(Persistence.Local);
            }
            const result = await client.current.LogIn(email, password);
            if (result.enabled) {
                setMfaState(result);
                return result;
            } else {
                parseAuthResponse(result);
                if (window.drift) {
                    const userData = parseToken(result.access_token);
                    window.drift.identify(userData.oid, {
                        email: userData.emails[0],
                        name: `${userData.given_name} ${userData.family_name}`,
                    });
                }
            }
        }
        working.current = false;
    };

    const logOut = async () => {
        working.current = true;
        accessToken.current = null;
        refreshToken.current = null;
        tokenExpirationEpoch.current = null;
        setMfaState(undefined);
        setClaims(null);
        client.current.ClearState();
        setLoggedIn(false);
        working.current = false;
        // TODO: figure out why is failing sometimes
        window.drift.reset();
    };

    const generateTokenWithRefresh = async (refresh) => {
        working.current = true;
        if (client.current) {
            const result = await client.current.RefreshToken(refresh);
            parseAuthResponse(result);
            return result.access_token;
        }
        working.current = false;
    };

    const setUp = useCallback(async () => {
        setLoggedIn(false);
        if (!client.current) {
            client.current = new AuthClient(config.clientId, config.apiHost);
        }

        try {
            if (!accessToken.current && client.current) {
                const state = client.current.FindState();
                if (state.authState) {
                    let claims = parseToken(state?.authState?.access_token);
                    accessToken.current = state?.authState?.access_token;
                    refreshToken.current = state?.authState?.refresh_token;
                    setClaims(claims);
                    tokenExpirationEpoch.current = claims?.exp * 1000;
                }
                if (state.mfaState) {
                    setMfaState(state.mfaState);
                }
            }
        } catch (e) {
            console.log("unable to determine current auth state: ", e);
            logOut().then();
            working.current = false;
            return;
        }

        axiosInstance.current.interceptors.request.use(function (config) {
            if (config?.headers?.hideErrorNotification) {
                setShowError(false);
            } else {
                setShowError(true);
            }
            return config;
        });

        let authInterceptor;
        if (accessToken.current) {
            authInterceptor = axiosInstance.current.interceptors.request.use(async function (config) {
                if (!accessToken.current) {
                    return config;
                }

                let token;
                if (Date.now() < tokenExpirationEpoch.current) {
                    token = accessToken.current;
                } else {
                    try {
                        token = await generateTokenWithRefresh(refreshToken.current);
                    } catch (e) {
                        setError("Session expired. Sign in again!");
                        await logOut();
                        throw e;
                    }
                }

                config.headers.Authorization = token;
                return config;
            });
            setLoggedIn(true);
        }

        const errorInterceptor = axiosInstance.current.interceptors.response.use(
            function (response) {
                return response;
            },
            function (e) {
                setError(resolveErrorMessage(e, "There was an unexpected error. Please try again later."));
                throw e;
            }
        );

        return {
            auth: authInterceptor,
            error: errorInterceptor,
        };
    }, []);

    const enableMfa = async (type, identity) => {
        if (client.current) {
            let result;
            try {
                result = await client.current.EnableMfa(type, identity, accessToken.current);
            } catch (e) {
                throw e;
            }
            setMfaState(result);
            return result;
        }
    };

    const disableMfa = async (code) => {
        if (client.current) {
            let result;
            try {
                result = await client.current.DisableMfa(code, accessToken.current);
            } catch (e) {
                throw e;
            }
            setMfaState(result);
            return result;
        }
    };

    const validateMfa = async (code, userId) => {
        working.current = true;
        if (client.current) {
            const result = await client.current.ValidateMfaCode(code, userId);
            parseAuthResponse(result);
        }
        working.current = false;
    };

    const sendMfaCode = async (userId) => {
        working.current = true;
        if (client.current) {
            const result = await client.current.SendMfaCode(userId, accessToken.current);
            parseAuthResponse(result);
        }
        working.current = false;
    };

    const forgotPassword = async (email) => {
        working.current = true;
        if (client.current) {
            await client.current.ForgotPassword(email);
        }
        working.current = false;
    };

    const resetPassword = async (newPassword, token) => {
        working.current = true;
        if (client.current) {
            await client.current.ResetPassword(newPassword, token);
        }
        working.current = false;
    };

    const validateResetPassword = async (token) => {
        working.current = true;
        if (client.current) {
            await client.current.ValidateResetPassword(token);
        }
        working.current = false;
    };

    const changePassword = async (user, password, newPassword) => {
        working.current = true;
        if (client.current) {
            await client.current.ChangePassword(user, password, newPassword, accessToken.current);
        }
        working.current = false;
    }

    useEffect(() => {
        working.current = true;

        const {auth, error} = setUp().then();

        setChild(children);
        working.current = false;
        return () => {
            if (auth) {
                axiosInstance.current.interceptors.request.eject(auth);
            }
            axiosInstance.current.interceptors.request.eject(error);
            setLoggedIn(false);
        };
    }, [accessToken.current]);

    const value = useMemo(() => ({
        logIn,
        logOut,
        enableMfa,
        disableMfa,
        validateMfa,
        sendMfaCode,
        forgotPassword,
        resetPassword,
        validateResetPassword,
        changePassword,
        mfaState: mfaState,
        working: working.current,
        loggedIn,
        claims,
        token: accessToken.current,
        axios: axiosInstance.current
    }), [logIn, logOut, loggedIn, claims]);

    const handleClose = (_, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setError(null);
        setShowError(true);
    };

    return (
        <AuthContext.Provider value={value}>
            {child}
            <Notification
                open={showError && !!error}
                severity="error"
                message={error}
                autoHideDuration={4000}
                onClose={handleClose}
            />
        </AuthContext.Provider>
    );
}

export { AuthProvider, AuthContext };
