import { createTheme } from "@mui/material/styles";
import { common } from "@mui/material/colors";

let theme = createTheme({
    components: {},
    palette: {
        primary: {
            main: "#2962FF",
            required: "#F44336",
        },
        principal: {
            main: "#001A6A",
        },
        lightblue: {
            main: "#EDF7FA",
            secondary: "#DCF0F5",
        },
        blue: {
            main: "#0078A2",
            dark: "#00344F",
            other: "#009100",
        },
        auxiliar: "#5A5A5A",
        bgDisabled: { main: "#EDEEED" },
        statesRules: {
            normal: "#666666",
            done: "#388E3C",
            wrong: "#D32F2F",
        },
        extras: {
            bg: "rgba(41, 98, 255, 0.04)",
            tooltip: "rgba(0, 0, 0, 0.87)",
            bgLinear: "linear-gradient(90deg, #f47d20 0%, #ffc60b 25%, #8eb935 50%, #0078a2 75%)",
            colorLiner: "linear-gradient(135deg,#0078a2 0,#8eb935 66%)",
            banner: "rgba(37, 40, 42, 0.76)",
            spam: "#F9F9F9",
            arrows: "rgba(0,0,0,.5)",
            arrowsHover: "rgba(0,0,0,.75)",
        },
    },
    typography: {
        h4: {
            fontSize: 28,
        },
        h6: {
            fontSize: 18,
            fontWeight: 400,
        },
        subtitle: {
            fontSize: 17,
        },
        fontFamily: "Avenir, Roman, Arial, sans-serif",
        fontFamilyArial: "Arial, sans-serif",
    },
});

theme = createTheme(theme, {
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                html, body, #root {
                    height: 100%
                }
                body {
                    background: #fff;
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                }
                a {
                    text-decoration: none;
                }
                .link{
                    text-decoration: underline;
                    color: ${theme.palette.blue.main}
                }
                .pointer{
                    cursor: pointer
                }
                .fw-heavy{
                    font-weight: 900;
                }
                img {
                    object-fit: contain;
                }
            `,
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.common.white,
                },
            },
        },
        MuiBox: {
            styleOverrides: {
                root: {
                    fontFamily: theme.typography.fontFamily,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: "contained",
                disableElevation: true,
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    "&.MuiButton-root": {
                        backgroundColor: theme.palette.blue.dark,
                        "&:hover": {
                            backgroundColor: theme.palette.blue.dark,
                        },
                        "&.MuiButton-outlined": {
                            backgroundColor: theme.palette.extras.bg,
                            color: theme.palette.blue.dark,
                        },
                        "&.MuiButton-text": {
                            backgroundColor: "inherit",
                            textTransform: "none",
                        },
                    },
                    "&.MuiListItemButton-root:hover": {
                        backgroundColor: theme.palette.lightblue.secondary,
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    "&.card-user-profile": {
                        marginTop: theme.spacing(3),
                        padding: theme.spacing(4),
                        ".MuiCardContent-root": {
                            padding: 0,
                        },
                    },
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    "&:last-child": {
                        paddingBottom: 16,
                    },
                },
            },
        },
        MuiCardMedia: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.lightblue.main,
                    padding: theme.spacing(2, 4),
                },
                img: {
                    objectFit: "contain",
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    "@media (min-width: 1400px)": {
                        maxWidth: 1400,
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        background: theme.palette.lightblue.secondary,
                        color: theme.palette.blue.main,
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    "&.Mui-selected": { color: theme.palette.blue.main, fontWeight: 900 },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    height: 5,
                    backgroundColor: theme.palette.blue.main,
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    "@media (min-width: 600px)": {
                        padding: 0,
                    },
                    "&.portal-toolbar": {
                        minHeight: theme.spacing(10),
                        padding: 0,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: common.white,
                    color: theme.palette.extras.tooltip,
                    boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                    fontSize: 11,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    "&.underline": {
                        textDecoration: "underline",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    "&.Mui-disabled": {
                        backgroundColor: theme.palette.bgDisabled.main,
                        color: theme.palette.auxiliar,
                    },
                },
            },
        },
    },
});

export default theme;
