import React, {useState} from "react";
import {Button, Grid, InputLabel, TextField} from "@mui/material";
import LayoutForm from "@components/layoutForm";
import {regexEmail} from "../Onboarding/constants";
import {BodyBox, BodyDiv, ImgBox, Layout} from "../Onboarding/styles";
import useAuth from "@auth/useAuth";
import {useNavigate} from "react-router-dom";
import {ToastTypes, useToast} from "../Components/memberToast";
import {ROUTE_NAMES, ROUTES} from "../../utils/constants";

const RecoverPassword = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const isValidEmail = regexEmail.test(email);
    const {forgotPassword} = useAuth();
    const navigate = useNavigate();
    const {showToast} = useToast();

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleBlur = (_) => {
        setError(isValidEmail ? "" : "Invalid email");
    };

    const handleFocus = () => {
        setError("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isValidEmail) {
            try {
                setLoading(true);
                await forgotPassword(email);
                navigate(ROUTES[ROUTE_NAMES.LOGIN], {
                    state: {
                        toast: {
                            message: "We’ve sent you an email with a link to reset your password. Check your spam folder if you haven’t received it after a few minutes. ",
                            type: ToastTypes.SUCCESS
                        }
                    }
                });
            } catch(e) {
                console.info(e);
                if (e?.response?.data?.errorMessage === 'invalid_client') {
                    showToast("User not found", ToastTypes.ERROR);
                    return;
                }
                showToast("Something went wrong", ToastTypes.ERROR);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Layout>
            <BodyDiv>
                <BodyBox>
                    <LayoutForm
                        title="Recover your password"
                        secondText="Do you remember your password? "
                        actionText="Sign in here"
                        actionLink="/login"
                    >
                        <form onSubmit={handleSubmit}>
                            <Grid container mt={3} spacing={2}>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="email">Email *</InputLabel>
                                    <TextField
                                        margin="dense"
                                        error={!!error}
                                        helperText={error}
                                        fullWidth
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={handleChange}
                                        autoFocus
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container mt={3} spacing={2}>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        disabled={!email || loading}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </LayoutForm>
                </BodyBox>
            </BodyDiv>
            <ImgBox/>
        </Layout>
    );
};

export default RecoverPassword;
