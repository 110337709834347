import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PortalContainer } from "@components";
import theme from "./theme";
import Welcome from "@pages/Welcome/welcome";
import {CssBaseline} from "@mui/material";
import Settings from "@pages/Settings/settings";
import UserProfile from "@pages/Settings/userProfile";
import SecuritySettings from "@pages/Settings/securitySettings";
import Onboarding from "@pages/Onboarding/onboarding";
import Login from "@pages/Login/login";
import RecoverPassword from "@pages/RecoverPassword/recoverPassword";
import ResetPassword from "@pages/ResetPassword/resetPassword";
import ChangePassword from "@pages/Settings/changePassword";
import MfaSetup from "@pages/Settings/mfaSetup"
import {MemberToastProvider} from "./pages/Components/memberToast";

function App() {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <MemberToastProvider>
                    <CssBaseline/>
                    <PortalContainer userType="admin">
                        <Routes>
                            <Route exact path="/" element={<Welcome/>}/>
                            <Route path="settings" element={<Settings/>}>
                                <Route index element={<Navigate to="/settings/user-profile" replace/>}></Route>
                                <Route path="user-profile" element={<UserProfile/>}/>
                                <Route path="security" element={<SecuritySettings/>}/>
                                <Route path="change-password" element={<ChangePassword/>}/>
                                <Route path="mfa-setup" element={<MfaSetup/>}/>
                            </Route>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/recover-password" element={<RecoverPassword/>}/>
                            <Route path="/reset-password" element={<ResetPassword/>}/>
                            <Route path="/onboarding" element={<Onboarding/>}/>
                            <Route path="*" element={<Navigate to="/"/>}/>
                        </Routes>
                    </PortalContainer>
                </MemberToastProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
