import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Typography, Box, Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SuccessMsg = styled(Stack, {
    name: "SuccessMsg",
    overridesResolver: (props, styles) => [styles.root],
})(() => ({}));

function SuccessMessage({ title, subtitle, buttonText, buttonAction }) {
    return (
        <SuccessMsg justifyContent="center" alignItems="center" spacing={2} mt={5}>
            <Box pb={2}>
                <CheckCircleOutlineIcon color="success" fontSize="large" />
            </Box>
            <Typography variant="h3">{title}</Typography>
            <Typography variant="h5">{subtitle}</Typography>
            <Box pt={4}>
                <Button variant="contained" size={"large"} onClick={buttonAction}>
                    {buttonText}
                </Button>
            </Box>
        </SuccessMsg>
    );
}

export default SuccessMessage;
