import { Box, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StyledIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => !["direction"].includes(prop),
})(({ theme, direction }) => ({
    position: "absolute",
    top: "50%",
    zIndex: 10,
    borderRadius: 0,
    width: theme.spacing(9),
    height: theme.spacing(9),
    [`${direction}`]: "-0.5rem",
    transform: "translateY(-50%)",
    backgroundColor: theme.palette.extras.arrows,
    color: "white",
    "&:hover": {
        backgroundColor: theme.palette.extras.arrowsHover,
        color: "white",
    },
}));
const CarouselWrapper = styled(Box)(() => ({
    position: "relative",
}));
const CarouselContentWrapper = styled(Box)(() => ({
    overflow: "hidden",
}));
const CarouselContent = styled(Box, {
    shouldForwardProp: (prop) => !["show", "currentIndex", "transitionEnabled"].includes(prop),
})(({ show, currentIndex, transitionEnabled }) => ({
    display: "flex",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    transform: `translateX(-${currentIndex * (100 / show)}%)`,
    transition: transitionEnabled ? "transform 500ms ease 0s" : "",

    "&::-webkit-scrollbar": {
        display: "none",
    },

    "& > *": {
        width: `calc(100% / ${show})`,
        flexShrink: 0,
        flexGrow: 1,
    },
}));

const Arrow = ({ direction, onClick }) => {
    return (
        <StyledIconButton direction={direction} onClick={onClick}>
            {direction === "left" ? <ArrowBackIcon fontSize="large" /> : <ArrowForwardIcon fontSize="large" />}
        </StyledIconButton>
    );
};

const Carousel = (props) => {
    const { children, infiniteLoop, autoScroll, delay = 2 * 1000 } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const show = useMemo(() => (isDesktop ? 4 : isTablet ? 2 : 1), [isDesktop, isTablet]);
    const timeoutRef = useRef(null);
    const length = children.length;
    const isRepeating = infiniteLoop && length > show;

    const initialIndex = useMemo(() => {
        return length <= show || !infiniteLoop ? 0 : show;
    }, [length, infiniteLoop, show]);

    const [currentIndex, setCurrentIndex] = useState(initialIndex);

    const [transitionEnabled, setTransitionEnabled] = useState(true);

    useEffect(() => {
        if (isRepeating) {
            if (currentIndex === 0) {
                setTransitionEnabled(false);
                setCurrentIndex(length);
            } else if (currentIndex === length + show) {
                setTransitionEnabled(false);
                setCurrentIndex(show);
            } else if (currentIndex === show || currentIndex === length) {
                setTransitionEnabled(true);
            }
        }
    }, [currentIndex, isRepeating, show, length]);

    const next = useCallback(() => {
        if (isRepeating || currentIndex < length - show) {
            setCurrentIndex((prevState) => prevState + 1);
        }
    }, [currentIndex, isRepeating, length, show]);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    useEffect(() => {
        if (autoScroll) {
            resetTimeout();

            timeoutRef.current = setTimeout(() => next(), delay);
            return () => {
                resetTimeout();
            };
        }
    }, [autoScroll, delay, next]);

    const prev = () => {
        if (isRepeating || currentIndex > 0) {
            setCurrentIndex((prevState) => prevState - 1);
        }
    };

    const renderExtraItems = (direction) => {
        let output = [];
        for (let index = 0; index < show; index++) {
            const position = direction === "prev" ? length - 1 - index : index;
            output.push(children[position]);
        }
        if (direction === "prev") {
            output.reverse();
        }
        return output;
    };

    return (
        <Box>
            <CarouselWrapper>
                {(isRepeating || currentIndex > 0) && <Arrow direction="left" onClick={prev} />}
                <CarouselContentWrapper>
                    <CarouselContent
                        // onTransitionEnd={handleTransitionEnd}
                        show={show}
                        currentIndex={currentIndex}
                        transitionEnabled={transitionEnabled}
                        // style={{
                        //     transition: !transitionEnabled ? "none" : undefined,
                        // }}
                    >
                        {length > show && isRepeating && renderExtraItems("prev")}
                        {children}
                        {length > show && isRepeating && renderExtraItems("next")}
                    </CarouselContent>
                </CarouselContentWrapper>
                {(isRepeating || currentIndex < length - show) && <Arrow direction="right" onClick={next} />}
            </CarouselWrapper>
        </Box>
    );
};

export default Carousel;
