import React, { useEffect, useMemo } from "react";
import { AppBar, Box, Button, Container, styled, Tab, Tabs, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserMenu from "./userMenu";
import logo from "@assets/od-logo.png";
import { settingsMenu, ROUTE_NAMES, ROUTES } from "@utils/constants";
import useSettingMenu from "@hooks/useSettingMenu";
import useAuth from "@auth/useAuth";
import useUser from "@auth/useUser";
import { PAGE_TAG_NAMES, ROUTES_PATH } from "../utils/constants";
const { WELCOME, SETTINGS, ONBOARDING, LOGIN, EXTRA, RECOVER_PASSWORD, RESET_PASSWORD } = ROUTE_NAMES;

const ImgBox = styled(Box)(({ theme }) => ({
    height: theme.spacing(4.3),
    [theme.breakpoints.up("md")]: {
        height: theme.spacing(5.3),
    },
}));

const pathsWithoutAppbar = [
    ROUTES[ONBOARDING],
    ROUTES[LOGIN],
    ROUTES[EXTRA],
    ROUTES[RECOVER_PASSWORD],
    ROUTES[RESET_PASSWORD],
];
const pathsWithFullWidth = [
    ROUTES[WELCOME],
    ROUTES[ONBOARDING],
    ROUTES[LOGIN],
    ROUTES[RECOVER_PASSWORD],
    ROUTES[RESET_PASSWORD],
];

function PortalContainer({ children, userType }) {
    const theme = useTheme();
    const { pathname } = useLocation();
    const isSettingPages = useMemo(() => pathname.includes(ROUTES[SETTINGS]), [pathname]);
    const showAppBar = useMemo(() => !pathsWithoutAppbar.includes(pathname), [pathname]);
    const isFullWidth = useMemo(() => pathsWithFullWidth.includes(pathname), [pathname]);
    const isOnboarding = useMemo(() => pathname.includes(ROUTES[ONBOARDING]), [pathname]);
    const isLogin = useMemo(() => pathname.includes(ROUTES[LOGIN]), [pathname]);
    const isExtra = useMemo(() => pathname.includes(ROUTES[EXTRA]), [pathname]);
    const isRecoverPassword = useMemo(() => pathname.includes(ROUTES[RECOVER_PASSWORD]), [pathname]);
    const isResetPassword = useMemo(() => pathname.includes(ROUTES[RESET_PASSWORD]), [pathname]);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const { current, handleChange, reset } = useSettingMenu();
    const { loggedIn, logOut } = useAuth();
    const { name } = useUser();
    const navigate = useNavigate();
    const isChildRender = loggedIn || isOnboarding || isLogin || isExtra || isRecoverPassword || isResetPassword;

    useEffect(() => {
        document.title = PAGE_TAG_NAMES[ROUTES_PATH[pathname]];
    }, [pathname]);

    const logInPopup = () => {
        navigate("/login");
    };

    const handleLogout = () => {
        logOut();
        navigate("/login");
    };

    useEffect(() => {
        if (!loggedIn && !isOnboarding && !isLogin && !isExtra && !isRecoverPassword && !isResetPassword) {
            navigate("/login");
        }
    }, [loggedIn, pathname, navigate, isOnboarding, isLogin, isExtra, isRecoverPassword, isResetPassword]);

    const renderBody = () => {
        if (isChildRender) {
            if (isFullWidth) {
                return children;
            } else {
                return (
                    <Container>
                        <Box>{children}</Box>
                    </Container>
                );
            }
        }
    };

    return (
        <>
            {showAppBar && (
                <AppBar>
                    <Container>
                        <Toolbar className={"portal-toolbar"}>
                            <Link to={ROUTES[WELCOME]}>
                                <ImgBox
                                    component="img"
                                    src={logo}
                                    cursor="pointer"
                                    alt="centro thumb"
                                    onClick={() => reset()}
                                />
                            </Link>
                            {loggedIn ? (
                                <UserMenu username={name} role={userType} logout={handleLogout} />
                            ) : (
                                <Button variant="outlined" onClick={logInPopup}>
                                    Log in
                                </Button>
                            )}
                        </Toolbar>
                        {isSettingPages && isMobile && (
                            <Tabs
                                value={current}
                                onChange={(_, newValue) => handleChange(newValue)}
                                variant="scrollable"
                                scrollButtons="auto"
                                textColor="secondary"
                                indicatorColor="secondary"
                            >
                                {settingsMenu.map((option, indexOpt) => (
                                    <Tab label={option.label} value={option} key={`${option.label}-${indexOpt}`} />
                                ))}
                            </Tabs>
                        )}
                    </Container>
                </AppBar>
            )}
            {renderBody()}
        </>
    );
}

export default PortalContainer;
