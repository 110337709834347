import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";
import { RulesBox } from "../styles";
import { statesRules } from "../constants";

const Rules = ({ rules }) => {
    return (
        <RulesBox>
            <List>
                {rules.map((rule) => (
                    <ListItem disablePadding key={rule.label}>
                        <ListItemIcon>
                            {rule.state.name !== statesRules.wrong.name ? (
                                <CheckIcon sx={{ color: rule.state.color }} />
                            ) : (
                                <ClearIcon sx={{ color: rule.state.color }} />
                            )}
                        </ListItemIcon>
                        <ListItemText primary={rule.label} sx={{ color: rule.state.color }} />
                    </ListItem>
                ))}
            </List>
        </RulesBox>
    );
};

export default Rules;
